.linkSection {
  --heading-font-weight: var(--font-weight-semibold);
  --heading-border-bottom-style: solid;
  --heading-border-bottom-width: 1px;
  --heading-margin-bottom: 0;
  display: flex;
  flex-direction: column;
}

.headingLabel {
  color: var(--footer-heading-label-color);
  font-size: var(--font-size-body);
}

.list {
  list-style: none;
  text-align: left;
  line-height: var(--leading-body);
  padding: 0;
}

.listItem {
  padding-top: var(--space-3x);
}
